const works_data = [
        {
            "id" : 1,
            "piece": "piece_1.jpg"
        },
        {
            "id" : 2,
            "piece": "piece_2.jpg"
        },
        {
            "id" : 3,
            "piece": "piece_3.jpg"
        },
        {
            "id" : 4,
            "piece": "piece_4.jpg"
        },
        {
            "id" : 5,
            "piece": "piece_5.jpg"
        },
        {
            "id" : 6,
            "piece": "piece_6.jpg"
        },
        {
            "id" : 7,
            "piece": "piece_7.jpg"
        },
        {
            "id" : 8,
            "piece": "piece_8.jpg"
        },
        {
            "id" : 9,
            "piece": "piece_9.jpg"
        },
        {
            "id" : 10,
            "piece": "piece_10.jpg"
        },
        {
            "id" : 11,
            "piece": "piece_11.jpg"
        },
        {
            "id" : 12,
            "piece": "piece_12.jpg"
        }
    ]
export default works_data