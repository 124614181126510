import React from 'react'
import Cards from "../comps/Cards"
const Work = () => {
  return (
    <section id = "work_section">
        <Cards />
    </section>
  )
}

export default Work